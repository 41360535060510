import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  head: {
    width:'100%', 
    margin:0,   
  },

  logoPetco: {
    width:'120px', 
    margin:'10px 0 5px 0',
  },

  iconoStore: {
    width:'26px', display: 'inline-block',
    padding:'0px 0 0 0', 
    position:'relative;  right: 6px'
  },

  toolbar1: {
    justifyContent:'center',
    backgroundColor: '#041A4F',
    minHeight:'75px' 
  },

  toolbar2: {
    justifyContent:'center',
    backgroundColor: '#D8D8D8', 
    maxHeight:'55px',
    minHeight:'55px'
  },

  tiendaLabel: {
    display: 'inline-block', 
    fontWeight: '400', 
    color:'#232323',
    fontSize:'18px'
  }

}));

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.head} elevation={0}>
        <Toolbar className={classes.toolbar1} >
          <img className={classes.logoPetco}src="image/logo-petco.png" id="logo-petco"/>
        </Toolbar>
      </AppBar>
    </div>
  );
}