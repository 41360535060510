
import { BrowserRouter } from 'react-router-dom';
import React, { Component } from 'react';
import Routes from './Routes';
import { AuthProvider } from './AuthProvider';
export default class App extends Component {
  render() {
  return (
    <AuthProvider>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    </AuthProvider>
  );
  }
}