import React, { useState, useEffect,useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ProductDetailView, HomeView, ProductDetailRFFView, VisualQrDetail } from './views';
import { AuthContext } from './AuthProvider';


const Auth=(props)=>{
  const{
    idTienda,
    idProducto,
    infoProducto,
    promocionProducto,
    nameTienda
  }=props
  const {currentUser} = useContext(AuthContext)

  return(
    <>
    {currentUser?
      <ProductDetailRFFView
        idTienda={idTienda}
        nameTienda={nameTienda}
        idProducto={idProducto}
        infoProducto={infoProducto}
        promocionProducto={promocionProducto}
      />
      :
      <ProductDetailView
        idTienda={idTienda}
        nameTienda={nameTienda}
        idProducto={idProducto}
        infoProducto={infoProducto}
        promocionProducto={promocionProducto}
      />
    }
    </>
  );
}

const VisualQR=(props)=>{
  const{
    nameTienda,
  }=props
  return(<>
    <VisualQrDetail nameTienda={nameTienda}/>
  </>)

}




const Routes = () => {

  const [idTienda, setIdTienda] = useState(null)
  const [idProducto, setIdProducto] = useState(null)
  const [infoProducto, setInfoProduct] = useState(null)
  const [promocionProducto, setPromocionProducto] = useState(null)
  const [nameTienda, setNameTienda] = useState(null)

  useEffect(async () => {
    const values = getQueryStringValue()
    setIdProducto(values.idProducto)
    setIdTienda(values.idTienda);


    const tiendasIdResponse = await fetch(process.env.REACT_APP_APP_PETCO_COM_MX +process.env.REACT_APP_TIENDAS_ID + values.idTienda, {
      method: "GET",
      headers: {
        'mode': 'no-cors',
        'Content-Type': 'application/json',
        'x-api-pc-wss': process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE,
      },
    })
    //console.log(tiendasIdResponse)

    const jsonTiendasIdResponse = await tiendasIdResponse.json()
    //console.log(jsonTiendasIdResponse)
    setNameTienda(jsonTiendasIdResponse.data)

    const productResponse = await fetch(process.env.REACT_APP_WWW_PETCO_COM_MX + process.env.REACT_APP_PETCOWS_V2_PETCO_PRODUCTS + values.idProducto + "?fields=FULL", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    })
    //console.log(productResponse)

    if (productResponse.status !== 200)
      return


    const jsonproductResponse = await productResponse.json()
    //console.log(JSON.stringify(jsonproductResponse))

    const productImages = []
    jsonproductResponse.images.forEach(item => {
      if (item.format == "product")
        productImages.push(item);
    })

    jsonproductResponse.productImages = productImages;

    const galleryImages = []
    let index = 0;
    jsonproductResponse.images.forEach(item => {
      if (item.format == "thumbnail") {
        galleryImages.push({ original: process.env.REACT_APP_WWW_PETCO_COM_MX + productImages[index].url, thumbnail: process.env.REACT_APP_WWW_PETCO_COM_MX + item.url });
        index++
      }
    })

    const result = removeDuplicates(galleryImages)

    //console.log(result)

    jsonproductResponse.galleryImages = result;

    const reviewsPercents = [0, 0, 0, 0, 0, 0]
    let counter = 0;
    let pluser = 0;
    jsonproductResponse.reviews.forEach(item => {
      reviewsPercents[Math.round(item.rating)]++;
      counter++;
      pluser += item.rating;
    })

    const average = pluser / (counter > 0 ? counter : 1)

    const reviewsInfo = {
      average: average,
      reviewsPercents: reviewsPercents,
      counter: counter,
      pluser: pluser
    }
    jsonproductResponse.reviewsInfo = reviewsInfo;

    setInfoProduct(jsonproductResponse)


    const response = await fetch(process.env.REACT_APP_APP_PETCO_COM_MX + process.env.REACT_APP_PRECIO_PRODUCT, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "x-api-pc-wss": process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE
      },
      body: JSON.stringify({ tienda_id: values.idTienda, material_id: values.idProducto })
    })

    if (response.status !== 200)
      return

    const sesionResponseJosn = await response.json()

    let finMayor = 0;
    let at = null;

    //console.log(sesionResponseJosn)
    if(sesionResponseJosn.replyCode!==200)
      return
    if(!sesionResponseJosn.data)
      return
    if(!sesionResponseJosn.data.promociones)
      return
    if(sesionResponseJosn.data.promociones.length>0){

      sesionResponseJosn.data.promociones.forEach(item => {
        index++
        if (parseInt(item.vig_fin) > finMayor) {
          finMayor = parseInt(item.vig_fin);
          at = item;
        }
      })

      sesionResponseJosn.validPromotion = at;

    }
    //console.log(sesionResponseJosn)


    setPromocionProducto(sesionResponseJosn)

  }, []);

  function removeDuplicates(arrayIn) {
    var arrayOut = [];
    arrayIn.forEach(item=> {
      try {
        if (JSON.stringify(arrayOut[arrayOut.length-1].thumbnail) !== JSON.stringify(item.thumbnail)) {
          arrayOut.push(item);
        }
      } catch(err) {
        arrayOut.push(item);
       }
    })
    return arrayOut;
}

  function getQueryStringValue() {
    const myArr = window.location.href.split('/')
    /*myArr.forEach(element => {
      console.log(element)
    });*/
    return {
      idTienda: myArr[5] || "none",
      idProducto: myArr[7] || "none"
    };
  }

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/"
        to="/home"
      />
      <Route
        exact={true}
        path='/home'
        children={<HomeView />}
      />
      <Route
        exact={true}
        path='/lps/t/:idTienda/p/:idProduct'
        children={<Auth 
        idTienda={idTienda}
        nameTienda={nameTienda}
        idProducto={idProducto}
        infoProducto={infoProducto}
        promocionProducto={promocionProducto}/>}
      />

      <Route
        //path='https://qr.petco.com.mx/lps/t/&&tienda&&/v/&&id_qr&&/o/&&id_qr_img&&'
        exact={true}
        path='/lps/t/:idTienda/v/:idQr/o/:idQrImg'
        children={<VisualQR 
          nameTienda={nameTienda}/>}
      />
      <Route
        //path='https://qr.petco.com.mx/lps/t/&&tienda&&/v/&&id_qr&&/o/&&id_qr_img&&'
        exact={true}
        path='/lps/t/:idTienda/v/:idQr/u/:urlVideo'
        children={<VisualQR 
          nameTienda={nameTienda}/>}
      />
    </Switch>
  );
};

export default Routes;