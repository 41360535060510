import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    descuento: { width: '100%', margin: '35px auto', textAlign: 'center', display: 'block' },
    promo: {
        display: 'inline-block',
        verticalAlign: 'middle', backgroundColor: '#EB0707', padding: '11px 8px'
    },
    promoTexto: {
        display: 'inline-block',
        textAlign: 'left', verticalAlign: 'middle', marginLeft: '10px'
    },
    textoDescuento: {
        fontWeight: 400, fontSize: '21px', margin: '4px auto 0', textAlign: 'left',
        lineHeight: '1em', color: '#fff'
    },
    texto2Descuento: {
        fontWeight: 400, color: '#fff',
        fontSize: '21px', margin: '0 auto', textAlign: 'left', lineHeight: '1em'
    },
    rojo: { fontWeight: 700, fontSize: '33px' },
    texto3Promo: {
        color: '#EB0707', fontWeight: 600,
        borderLeft: '1px solid #EB0707', paddingLeft: '10px',
        fontSize: '17px', margin: '0 auto', textAlign: 'left'
    },
    precio: { width: '100%', margin: '40px auto' },
    textoPrecio: { fontWeight: 500, fontSize: '20px', margin: '4px auto 0', textAlign: 'center' },
    boldPrecio: { fontWeight: 700 },
    tache: { textDecoration: 'line-through' }
}));

export default function Discount(props) {
    const classes = useStyles();
    const {data} = props;


    if(!data)
        return(<></>)

    return (
        <section className={classes.descuento}>
            {data.validPromotion ?
                <>
                    {data.validPromotion.valor_desc > 0 ?
                        <>
                            <article className={classes.promo}>
                                <p className={classes.textoDescuento}><span className={classes.rojo}>{data.validPromotion.valor_desc}%</span> de</p>
                                <p className={classes.texto2Descuento}>descuento</p>

                            </article>

                        </>
                        : <article className={classes.promoTexto}>
                            <p className={classes.texto3Promo}>{data.validPromotion.descripcion}</p>
                        </article>
                    }
                </>
                : <></>
            }

            <article className={classes.precio}>

                <p className={classes.textoPrecio}><span className={classes.boldPrecio}>PRECIO: </span> ${data.data.precio} {/*<span className={classes.tache}>Antes
                {data.validPromotion.precio}</span>*/}</p>
            </article>
        </section>
    );
}

