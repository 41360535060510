import React from 'react'
import Footer from './components/Footer';
import Header from './components/Header';
import Review from './components/Review';
import Accordion from './components/Accordion';
import Discount from './components/Discount';
import Product from './components/Product';
import MascotasRFF from './components/MascotasRFF';


const ProductDetailView = (props) => {

    const{
        idTienda,
        idProducto,
        infoProducto,
        promocionProducto,
        nameTienda
      }=props

    return (
        <div>
            <Header tienda={nameTienda}></Header>
            <Product data={infoProducto}></Product>
            <Discount data={promocionProducto}></Discount>
            {/*<MascotasRFF idTienda={idTienda} idProducto={idProducto} ></MascotasRFF>*/}
            <Accordion data={infoProducto}></Accordion>
            <Review data={infoProducto}></Review>
            <Footer></Footer>
        </div>
    );
};

export default ProductDetailView;