import React from 'react'
import Footer from './components/Footer';
import Header from './components/Header';
import Body from './components/Body'
import { useEffect } from 'react';


function VisualQrDetail(props){

    const { nameTienda } = props

    useEffect(async() => {
        const visualQrId1 = window.location.href.split('/v/')[1]
        const idVisual = visualQrId1.split('/')[0]
        const noTienda = window.location.href.split('/')[5]

        const response = await fetch("https://app.petco.com.mx/visualesQR", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-api-pc-wss": process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE
            },
            body: JSON.stringify({ idClubPetco: "null", noTienda: noTienda, idVisual: idVisual })
        })

        if (response.status !== 200)
            return

        const json = await response.json()

    }, [])

    return (
        <div>
            <Header tienda={nameTienda}></Header>
            <Body />
            <Footer></Footer>
        </div>
    );
};

export default VisualQrDetail;