import React, { useEffect, useState } from 'react'
import Footer from './components/Footer';
import Header from './components/Header';
import Review from './components/Review';
import Accordion from './components/Accordion';
import Discount from './components/Discount';
import Product from './components/Product';
import SignIn from './components/SignIn';
export function ProductDetailView (props){

    const{
        idTienda,
        idProducto,
        infoProducto,
        promocionProducto,
        nameTienda
      }=props

    console.log(idTienda)

    useEffect(async()=>{

        if(!idProducto)
            return
        if(!idTienda)
            return

        const retroResponse = await fetch("https://app.petco.com.mx/registerQR",{
                method: "POST",
                    headers: {
                    'Content-Type': 'application/json',
                    'x-api-pc-wss': process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE
                    },
                    body:JSON.stringify({
                        idClubPetco: "null",
                        idMaterial: idProducto,
                        noTienda: idTienda
                    })
                })

        console.log(retroResponse)
        if(retroResponse.status!==200)
                console.log("error with https://app.petco.com.mx/registerQR service")
    },[idProducto])

    return (
        <div>
            <Header tienda={nameTienda}></Header>
            <Product data={infoProducto}></Product>
            <Discount data={promocionProducto}></Discount>
            {/*<SignIn idProducto={idProducto} idTienda={idTienda}></SignIn>*/}
            <Accordion data={infoProducto}></Accordion>
            <Review data={infoProducto}></Review>
            <Footer></Footer>
        </div>
    );
};