import React from 'react'
import { makeStyles, } from '@material-ui/core/styles';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
    backgroundColor: '#fafafa',
    margin: '0 auto 0px',
    textAlign: 'center',
    width: '50%',
    minWidth: '300px',
    maxWidth:'450px'
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
}));

export default function Product(props) {

  const { data } = props;
  const classes = useStyles();

  if (!data)
    return (<></>)

  return (
    <div className={classes.root}>
      <h2><span style={{ fontWeight: 'bolder' }}>{data.summary}</span><br /> {/*{data.description.split(',')[0]}*/}</h2>
      <ImageGallery showFullscreenButton={false} showPlayButton={false} items={data.galleryImages} />
    </div>
  )
}