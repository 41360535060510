import React from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import QRReader from './QRReader';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({

    body: {
        fontFamily: '"Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif"',
        color: '#232323', fontFamily: 'Arial, sans-serif'
    },

    cajaInfo: {
        maxWidth: '100%',
        margin: '0 auto', 
        position: 'relative',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },

    tituloQr: {
        fontSize: '18px', 
        width: '60%',
        margin: '40px auto 3px',
        color: '#232323'
    },
    boldQr1: { fontSize: '28px', fontWeight: 800, color: '#232323', width: '100%', margin: '0px auto 40px' },

    escan: {
       
    },

    qrImage: { width: '400px', maxWidth: '95%',display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto' },
}));

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#E74C3C'),
      backgroundColor: '#00AAE7',
      '&:hover': {
        backgroundColor: '#00AAE7',
      },
      width: '250px', margin: '0 auto 30px', border: 0, backgroundColor: '#00AAE7', padding: '10px 0', fontSize: '18px', color: '#fff', fontWeight: 600,
      borderRadius: '10px'
    },
  }))(Button);

const styles = (theme) => ({
root: {
    margin: 0,
    padding: theme.spacing(2),
},
closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
},
});

const DialogTitle = withStyles(styles)((props) => {
const { children, classes, onClose, ...other } = props;
return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
        </IconButton>
    ) : null}
    </MuiDialogTitle>
);
});

const DialogContent = withStyles((theme) => ({
root: {
    padding: 0,
},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
root: {
    margin: 0,
    padding:0,
},
}))(MuiDialogActions);

export default function Body() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <article className={classes.cajaInfo}>
            <p className={classes.tituloQr}>Para poder ver nuestras promociones es necesario</p>
            <p className={classes.boldQr1}>ESCANEAR EL QR</p>
           
            <ColorButton 
                type="submit"  
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                >Escanear QR</ColorButton>
            <img src="image/qr-image.png" id="qr-image" className={classes.qrImage} />

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogContent>
                <QRReader></QRReader>
                </DialogContent>
            </Dialog>
        </article>
    );
}