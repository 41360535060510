import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useState } from 'react';
import { margin } from '@mui/system';
import EmbedVideo from './EmbedVideo';
import { Picture, FullsizePicture } from 'react-responsive-picture';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        minHeight: 200,

        marginTop: 20,
        marginBottom: 20,
        marginLeft:20,
        marginRight:20
    },

}));

export default function Body() {
    const classes = useStyles();
    const [kindBundle, setKindeBundle] = useState('o')
    const [url, setUrl] = useState(null)

    useEffect(() => {
        let array = window.location.href.split('/o/')
        if (array.length > 1) {
            setKindeBundle('o')
        } else {
            array = window.location.href.split('/u/')
            setKindeBundle('u')
        }
        setUrl(array[1])


    }, [])

    return (

        <div className={classes.root}>
            <>{kindBundle == 'o' ?
                <img
                    style={{
                        width: '100%',
                        aspectRatio: 'auto 620 / 348',
                        height: '100%',
                    }}
                    //src="https://generacionxbox.com/wp-content/uploads/2021/10/halo-infinite-wallpapers.jpg"
                    src={"https://citas.petco.com.mx/QRVisuales/" + url}
                />
                : <EmbedVideo url={url}></EmbedVideo>
            }
            </>
        </div>
    );
}