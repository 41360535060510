import React from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 14%)',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      minHeight: '30px',
    },
    backgroundColor: '#F4F4F4',
    minHeight: '30px',
    marginTop:5,
    marginBottom:5,
    border:0
  },
  expanded: {minHeight: '30px',},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    '&$expanded': {
      borderBottom: '2px solid #00AAE7',
      color: '#6B6B6B!important',
      fontWeight:'bolder',
      minHeight: '30px',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      minHeight: '30px'
    },
  },
  expanded: { minHeight: '30px'},

})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    seccionAccord:{width:'100%',margin:'0 auto', maxWidth:'90%'},
    contenidoAcordeon: {
      boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 14%)',
      backgroundColor: '#fff',
      margin: '0 auto 30px',
      textAlign: 'center',
      width: '450px',
      maxWidth: '90%'
    },
    atributo:{
        display: 'table',
        bordeRCollapse: 'separate',
        boxSizing: 'border-box',
        textIndent: 'initial',
        borderSpacing: '2px',
        borderColor: 'grey'
    },
    tbody:{
        display: 'table-row-group',
        verticalAlign: 'middle',
        borderColor: 'inherit'
    },
    td:{
        borderTop: '1px solid #d5dadc',
        padding: '8px',
        color: '#6B6B6B',
        fontSize: '13px'
    },
    gris:{
        backgroundColor: '#fafafa',
    },
    ul: {
        width: '90%',
        maxWidth: '100%',
        margin: '0 auto',
        color: '#6B6B6B',
        fontWeight: 100,
        fontSize: '13px',
        lineHeight: '20px',
    },
    li: {
        width: '95%'
    },

    accordion_tab:{
      borderbottom: '2px solid #00AAE7',
      color:'#6B6B6B!important'
    }
}));

export default function AccordionComponent(props) {
  const {data} = props;
  const [expanded, setExpanded] = React.useState('panel1');
  const classes = useStyles();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if(!data)
        return(<></>)

  return (
 <div className={classes.contenidoAcordeon}>
      <>
    {data.description?
      <>
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
        <div className={classes.accordion_tab}>Descripción</div>
        </AccordionSummary>
        <AccordionDetails>
        <p className={classes.textoAcordeon}><p className="content" dangerouslySetInnerHTML={{__html: (data.description.split('Beneficios')[0]).split('<p>Descripci&oacute;n</p>')[1]}}></p></p>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"  expandIcon={<ExpandMoreIcon />}>
        <div className={classes.accordion_tab}>Beneficios</div>
        </AccordionSummary>
        <AccordionDetails>
        <div className="content" dangerouslySetInnerHTML={{__html: data.description.split('Beneficios')[1]}}></div>
        </AccordionDetails>
      </Accordion>
      </>:<></>
    }

      {data.ingredients?
      <>
      <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon />} >
        <div className={classes.accordion_tab}>Atributos</div>
        </AccordionSummary>
        <AccordionDetails>
        <div className="content" dangerouslySetInnerHTML={{__html: data.ingredients.split("<div id='tableIngredients'>")[1]}}></div>
        
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
        <div className={classes.accordion_tab}>Ingredientes</div>
        </AccordionSummary>
        <AccordionDetails>
        <p className={classes.textoAcordeon}><p className="content" dangerouslySetInnerHTML={{__html: (data.ingredients.split("<div id='tableIngredients'>")[0]).split('<p>Ingredientes</p>')[1]}}></p></p>

       
        </AccordionDetails>
      </Accordion>
      </>:<></>
    }
  
   
    </>
  </div>
  );
}
