import React, { Component, useState } from 'react'
import QrReader from 'react-qr-reader'
 
const QRReader=()=> {
  const [state,setState] = useState({
    result: 'No result'
  })
 
    const handleScan = data => {
        if (data) {
            openInNewTab(data)
            setState({
                result: data
            })
        }
    }

    const openInNewTab = (url) => {
        try{
            /*const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) {
                console.log("newWindow")
                newWindow.opener = null
            }*/
            window.location.assign(url)
        }catch(error){
            console.log(error)
        }
    }
    const handleError = err => {
        console.error(err)
    }
    return (
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '400px'}}
        />
    )
}

export default QRReader