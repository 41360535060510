import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, withStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import PetsIcon from '@mui/icons-material/Pets';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';

import Typography from '@mui/material/Typography';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FCBD00',
  },
  '& .MuiRating-iconHover': {
    color: '#FCBD00',
  },
});

function CustomizedRating(props) {
    const{value,...rest}=props
  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
      }}
    >
      <StyledRating
        name="customized-color"
        value={value}
        readOnly
        //getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.01}
        size="large"
        style={{fontSize:"3rem"}}
        max={5}
        icon={<PetsIcon fontSize="inherit" />}
        emptyIcon={<PetsIcon fontSize="inherit" />}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
    review: {
        width: '450px', maxWidth: '90%', margin: '50px auto',
        textAlign: 'center',
    },
    tituloReview: { fontWeight: 700, color: '#00AAE7', margin: '0 auto', textAlign: 'center', fontSize: '16px' },
    reviews: { width: '100%', margin: '0 auto', textAlign: 'center' },
    huellitas: {
        width: '100px', margin: '0 auto', display: 'inline-block',
        position: 'relative; top:6px right: 6px'
    },
    calif: { display: 'inline-block', fontSize: '14px' },
    numero: { width: '40px', display: 'inline-block', margin: '0 auto' },
    huellita: {
        width: '25px', margin: '0 auto', display: 'inline-block',
        position: 'relative; top:8px right: 4px'
    },
    calificacion: {
        display: 'inline-block', fontSize: '12px',
        fontWeight: 600
    },
    resena: { width: '90%', margin: '0 auto', textAlign: 'center' },
    barra: {
        width: '180px', backgroundColor: '#EDEDED', margin: '0 auto',
        display: 'inline-block', position: 'relative; top:4px left:0',
        borderRadius: '4px', textAlign: 'left'
    },
    carga: {
        width: '160px', backgroundColor: '#FCBD00', margin: 0,
        height: '15px', borderRadius: '4px'
    },
    cargaCuatro: {
        width: '20px', backgroundColor: '#FCBD00', margin: 0,
        height: '15px', borderRadius: '4px'
    },
    cargaTres: {
        width: '10px', backgroundColor: '#FCBD00', margin: 0,
        height: '15px', borderRadius: '4px'
    },
    cargaCero: {
        width: '0px', backgroundColor: '#FCBD00', margin: 0,
        height: '15px', borderRadius: '4px'
    },
    porcentaje: { width: '42px', display: 'inline-block', margin: '0 auto' },
    porcent: {
        display: 'inline-block', fontSize: '12px',
        fontWeight: 600
    },
    azul: { color: '#00AAE7' },


    /* The Modal (background) */
    modal: {
        display: 'none',
        position: 'fixed',
        zIndex: 1,
        paddingTop: '100px',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
    },

    like: {
        display: 'inline-block', width: '45%',
        textAlign: 'right', maxWidth: '45%'
    },

    /*comment: {
        backgroundColor: '#FCBD00',
        borderRadius: '10px',
        padding: '8px 15px',
        width: '220px',
        margin: '30px auto 0px',
        color: '#434343',
        fontSize: '13px',
        fontWeight: 500,
        border: 'none',
    },*/

    tituloComentario: { fontWeight: 600, fontSize: '10px', color: '#232323', margin: '0px auto 0', textAlign: 'left', width: '90%' },

    textoComentario: { fontWeight: 300, fontSize: '10px', color: '#434343', margin: '10px auto', textAlign: 'left', width: '90%' },

    infoComentario: { width: '100%', margin: '0 auto', display: 'inlineBlock' },

    cajaFull: {
        boxShadow: '0px 0px 8px 0px rgb(0 0 0 / 25%)',
        backgroundColor: '#EEEEEE', width: '100%', paddingTop: '10px', borderBottom: '2px solid #FCBD00', margin: '10px auto', textAlign: 'center'
    },
    nameComment: { 
        display: 'inline-block',
        width: '50%',
        textAlign: 'left',
        maxWidth: '45%',
        margin: '0px auto 0',
        fontSize: '12px',
        fontWeight: 500
    },
    nameComment2: {
        display: 'inline-block',
        width: '50%',
        textAlign: 'right',
        maxWidth: '45%',
        margin: '0px auto 0',
    },

    numero: {
        width: '40px',
        display: 'inline-block',
        margin: '0 auto',
    },

    calificacion: {
        display: 'inline-block',
        fontSize: '12px',
        fontWeight: 600
    },

    huellita: {
        width: '25px',
        margin: '0 auto',
        display: 'inline-block',
        position: 'relative',
    },

    fecha: {
        display: 'inline-block', width: '44%', textAlign: 'left',
        verticalAlign: 'middle', maxWidth: '45%', fontSize: '11px', margin: '10px auto'
    },

    cajaLike: {
        backgroundColor: '#00AAE7', padding: '5px',
        textAlign: 'right', position: 'relative', right: '0px', float: 'right'
    },

    number: { display: 'inline-block', verticalAlign: 'middle', width: '16px', margin: '0 auto', color: '#fff', position: 'relative', top: '4px', right: '8px', fontSize: '12px' },

    iconLike: {
        width: '15px',
        margin: '0 auto',
        display: 'inline-block',
        position: 'relative',
        right: '3px',
        verticalAlign: 'middle'
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#FCBD00'),
      borderRadius: '10px',
      marginTop:'10px',
      backgroundColor: '#FCBD00',
      '&:hover': {
        backgroundColor: '#FCBD00',
      },
    },
}))(Button);

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#FCBD00",
    },
  }))(LinearProgress);

  const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor:'#FCBD00',
        
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#454545',
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography style={{fontWeight:700, color:'#454545'}}variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);


  const WarningTheme = createTheme({
    overrides: {
      MuiSnackbarContent: {
        root: {
          backgroundColor: '#FCBD00',
          color:'#454545'
        }
      }
    }
  });

export default function Review(props) {
    const classes = useStyles();
    const {data } = props;

    const [open, setOpen] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [snackBarMessage, setSnackBarMessage] = useState('');;

    const handleClickOpen = () => {
        if(data.reviews.length>0){
            setOpen(true);
            
        }else{
            setOpenSnackBar(true)
            setSnackBarMessage("Este producto no tiene reviews")
        }
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    const handleClose = () => {
    setOpen(false);
    };


    if(!data)
      return(<></>)

    if(!data.reviews)
        return(<></>)

    if(data.reviews.length==0)
        return(<div>
            <section className={classes.review}>
                <p className={classes.tituloReview}>Reseñas de clientes</p>
                <article className={classes.reviews}>
                    {/*<img src="../../../../image/huellitas.png" id="huellitas" />*/}
                    <CustomizedRating value={data.reviewsInfo.average.toFixed(2)}></CustomizedRating>
                    <p className="calif">{data.reviewsInfo.average.toFixed(2)} de 5 huellitas <span className={classes.azul}>({data.reviewsInfo.counter} Reseñas)</span></p>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>5</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={0}/>
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{0} %</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>4</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={0} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{0} %</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>3</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={0} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{0}%</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>2</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={0}/>
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{0} %</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>1</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={0} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{0} %</p>
                    </article>
                </article>
                <ColorButton onClick={handleClickOpen}><strong>ver comentarios</strong></ColorButton>                        
            </section>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Comentarios
                </DialogTitle>
                <DialogContent dividers>
                {data.reviews.map((element,index)=>{
                            return(
                            <article key={index} className={classes.cajaFull}>
                                <div  className={classes.nameComment}>{element.alias}</div>
                                <div  className={classes.nameComment2}>
                                    <article className={classes.numero}>
                                        <p className={classes.calificacion}>5</p>
                                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                                    </article>
                                </div>

                                <p  className={classes.tituloComentario}>{element.headline}</p>
                                <p  className={classes.textoComentario}>{element.comment}</p>


                                <article className={classes.infoComentario}>
                                    <div className={classes.fecha}>{element.date.split('T')[0]}</div>

                                    <div  className={classes.like}>
                                        <article className={classes.cajaLike}>
                                            <p className={classes.number}>{element.rating}</p>
                                            <img src="../../../../image/like.png" className={classes.iconLike} />
                                        </article>
                                    </div>

                                </article>
                            </article>
                            )
                            })}
                </DialogContent>
            </Dialog>
            <ThemeProvider theme={WarningTheme}>
                <Snackbar
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                        }}
                        open={openSnackBar}
                        autoHideDuration={6000}
                        message={snackBarMessage}
                        onClose={handleCloseSnackBar}
                    />
            </ThemeProvider>
        </div>)

    return (
        <div>
            <section className={classes.review}>
                <p className={classes.tituloReview}>Reseñas de clientes</p>
                <article className={classes.reviews}>
                    {/*<img src="../../../../image/huellitas.png" id="huellitas" />*/}
                    <CustomizedRating value={data.reviewsInfo.average.toFixed(2)}></CustomizedRating>
                    <p className="calif">{data.reviewsInfo.average.toFixed(2)} de 5 huellitas <span className={classes.azul}>({data.reviewsInfo.counter} Reseñas)</span></p>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>5</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={parseInt(data.reviewsInfo.reviewsPercents[5]*100/data.reviewsInfo.counter)} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{(data.reviewsInfo.reviewsPercents[5]*100/data.reviewsInfo.counter).toFixed(1)} %</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>4</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={parseInt(data.reviewsInfo.reviewsPercents[4]*100/data.reviewsInfo.counter)} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{(data.reviewsInfo.reviewsPercents[4]*100/data.reviewsInfo.counter).toFixed(1)} %</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>3</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={parseInt(data.reviewsInfo.reviewsPercents[3]*100/data.reviewsInfo.counter)} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{(data.reviewsInfo.reviewsPercents[3]*100/data.reviewsInfo.counter).toFixed(1)} %</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>2</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={parseInt(data.reviewsInfo.reviewsPercents[2]*100/data.reviewsInfo.counter)} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{(data.reviewsInfo.reviewsPercents[2]*100/data.reviewsInfo.counter).toFixed(1)} %</p>
                    </article>
                </article>
                <article className={classes.resena}>
                    <article className={classes.numero}>
                        <p className={classes.calificacion}>1</p>
                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                    </article>
                    <article className={classes.barra}>
                    <BorderLinearProgress variant="determinate" value={parseInt(data.reviewsInfo.reviewsPercents[1]*100/data.reviewsInfo.counter)} />
                    </article>
                    <article className={classes.porcentaje}>
                        <p className={classes.porcent}>{(data.reviewsInfo.reviewsPercents[1]*100/data.reviewsInfo.counter).toFixed(1)} %</p>
                    </article>
                </article>
                <ColorButton onClick={handleClickOpen}><strong>ver comentarios</strong></ColorButton>                        
            </section>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Comentarios
                </DialogTitle>
                <DialogContent dividers>
                {data.reviews.map((element,index)=>{
                            return(
                            <article key={index} className={classes.cajaFull}>
                                <div  className={classes.nameComment}>{element.alias}</div>
                                <div  className={classes.nameComment2}>
                                    <article className={classes.numero}>
                                        <p className={classes.calificacion}>5</p>
                                        <img src="../../../../image/huellita.png" className={classes.huellita} />
                                    </article>
                                </div>

                                <p  className={classes.tituloComentario}>{element.headline}</p>
                                <p  className={classes.textoComentario}>{element.comment}</p>


                                <article className={classes.infoComentario}>
                                    <div className={classes.fecha}>{element.date.split('T')[0]}</div>

                                    <div  className={classes.like}>
                                        <article className={classes.cajaLike}>
                                            <p className={classes.number}>{element.rating}</p>
                                            <img src="../../../../image/like.png" className={classes.iconLike} />
                                        </article>
                                    </div>

                                </article>
                            </article>
                            )
                            })}
                </DialogContent>
            </Dialog>
            <ThemeProvider theme={WarningTheme}>
                <Snackbar
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                        }}
                        open={openSnackBar}
                        autoHideDuration={6000}
                        message={snackBarMessage}
                        onClose={handleCloseSnackBar}
                    />
            </ThemeProvider>
        </div>
    );
}

