import React, { useEffect, useState } from 'react'
import Footer from './components/Footer';
import Header from './components/Header';
import Body from './components/Body';

const Home = () => {
    return (
        <>
        <Header ></Header>
        <Body></Body>
        <Footer></Footer>
        </>
    );
}

export default Home;