import React, { useState, createContext, useContext, useEffect } from "react";
export let AuthContext = createContext({});

export const AuthProvider=({children})=>{
    const [currentUser,setCurrentUser] = useState(null);
    const [signIn,setSignIn] = useState(false);
    const [idProducto,setIdProducto] = useState(false);

    useEffect(async() => {

        if(localStorage.getItem('tokenEmail')){

            //console.log(localStorage.getItem('tokenEmail'))
            const tokenEmail = JSON.parse(localStorage.getItem('tokenEmail'))
            //console.log(tokenEmail.email)
            const userResponse = await fetch(process.env.REACT_APP_WWW_PETCO_COM_MX+process.env.REACT_APP_PETCOWS_V2_PETCO_USERS+tokenEmail.email+"/?fields=FULL",{
                method: "GET",
                headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer "+tokenEmail.access_token
                },
            })
        
            //console.log('UserResponse')
            //console.log(userResponse)
            if(userResponse.status!==200){
                setCurrentUser(null)
                localStorage.clear()
                return
            }
            
            const jsonUserResponse = await userResponse.json()

            const retroResponse = await fetch("https://app.petco.com.mx/registerQR",{
                method: "POST",
                    headers: {
                    'Content-Type': 'application/json',
                    'x-api-pc-wss': process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE
                    },
                    body:JSON.stringify({
                        idClubPetco: jsonUserResponse.idClubPetco,
                        idMaterial: tokenEmail.idProducto,
                        noTienda: tokenEmail.idTienda
                    })
                })

            console.log(retroResponse)

            const json = await retroResponse.json()

            console.log(json)

            setCurrentUser(jsonUserResponse)
        }

        /*const interval = setInterval(async() => {
           

        }, 20000);
      
        return () => {
          clearInterval(interval);
        };*/
    }, [signIn]);

    return(
        <AuthContext.Provider value={{currentUser,setSignIn,setIdProducto}}>
            {children}
        </AuthContext.Provider>
    )
}