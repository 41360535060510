import React, {useState, useEffect,useContext}from 'react';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import Button from '@material-ui/core/Button';
import {AuthContext} from '../../../AuthProvider'
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, withStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles((theme) => ({
    registro:{
        boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 14%)',
        backgroundColor: '#fff',
        margin: '0 auto 30px',
        textAlign: 'center',
        width: '450px',
        maxWidth: '90%'
    },
    contenidoAsesor: {
        width:'90%', 
        margin:'0 auto 20px',
        padding:'10px 0px 30px'
    },
    asesor:{fontWeight: 600, fontSize:'19px',margin:'15px auto 0'},
    descripcion:{fontWeight: 300, fontSize:'14px',
        margin:'15px auto 10px', width:'90%', color:'#434343'},

    form: {
        width: '80%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'inline-block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
      },

      root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      }
}));

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#E74C3C'),
      backgroundColor: '#00AAE7',
      '&:hover': {
        backgroundColor: '#00AAE7',
      },
    },
}))(Button);


const ErrorTheme = createTheme({
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#CB4335'
      }
    }
  }
});
const DoneTheme = createTheme({
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#28B463'
      }
    }
  }
});

export default function SignIn(props) {
  const {idProducto,idTienda}=props
  const classes = useStyles();
  const {setSignIn} = useContext(AuthContext)
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [openSnackBarError, setOpenSnackBarError] = useState(false);
  const [openSnackBarDone, setOpenSnackBarDone] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  async function handleSignIn(event){
    event.preventDefault();
    const { email, password } = event.target.elements;
    const params = new URLSearchParams();

    params.append("grant_type", "password");
    params.append("username", email.value);
    params.append("password", password.value);
    params.append("client_id", process.env.REACT_APP_CONSUMER_KEY);
    params.append("client_secret", process.env.REACT_APP_CONSUMER_SECRET_KEY);

    //console.log(process.env.REACT_APP_CONSUMER_SECRET_PRODUCTION)

    //console.log(process.env.REACT_APP_WWW_PETCO_COM_MX+process.env.REACT_APP_AUTHORIZATIONSERVER_OAUTH_TOKEN)
    const authResponse = await fetch(process.env.REACT_APP_WWW_PETCO_COM_MX+process.env.REACT_APP_AUTHORIZATIONSERVER_OAUTH_TOKEN,{
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params
    })

    //console.log('AuthResponse')
    //console.log(authResponse)
    if(authResponse.status!==200){
      setSnackBarMessage('Response with status: '+authResponse.status);
      setOpenSnackBarError(true);
      return
    }
    const jsonAuthResponse=await authResponse.json()
    //console.log('JsonAuthResponse')   
    //console.log(jsonAuthResponse);

    localStorage.setItem('tokenEmail',JSON.stringify({
      access_token: jsonAuthResponse.access_token,
      email:  email.value,
      idProducto:idProducto,
      idTienda:idTienda
    }))

    setSignIn(true)

    setSnackBarMessage('Welcome');
              setOpenSnackBarDone(true);
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  useEffect(async() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

    const handleCloseSnackBar = () => {
      setOpenSnackBarDone(false);
      setOpenSnackBarError(false);
    };


  return (
    <section className={classes.registro}>
        <article className={classes.contenidoAsesor}>
            <p className={classes.asesor}>Asesor Nutricional</p>
            <p className={classes.descripcion}>Escribe tu correo electrónico o teléfono con el que estés registrado en Petco.
            </p>

            <form className={classes.form} onSubmit={handleSignIn} noValidate>
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              size="small"
              helperText={
                hasError('email') ? formState.errors.email[0] : null
              }
              label="Correo electrónico"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
              margin="normal"
              required
              id="email"
              autoComplete="current-email"
            />
            <TextField
              className={classes.textField}
              error={hasError('password')}
              fullWidth
              size="small"
              helperText={
                hasError('password') ? formState.errors.password[0] : null
              }
              label="Contraseña"
              name="password"
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
              margin="normal"
              required
              id="password"
              autoComplete="current-password"
            />
            <ColorButton    
                type="submit"  
                variant="contained"
                color="primary"
                className={classes.submit}
                //disabled={!formState.isValid}
              >
                Acceder
              </ColorButton>
          </form>
          <ThemeProvider theme={ErrorTheme}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={openSnackBarError}
          autoHideDuration={6000}
          message={snackBarMessage}
          onClose={handleCloseSnackBar}
        />
        </ThemeProvider>
        <ThemeProvider theme={DoneTheme}>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={openSnackBarDone}
            autoHideDuration={6000}
            message={snackBarMessage}
            onClose={handleCloseSnackBar}
          />
        </ThemeProvider>
        </article>
    </section>
  );
}