import React from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";

function EmbedVideo(props) {
    const { url } = props

    console.log(decodeURIComponent(url))
    console.log(decodeURIComponent(url).replace('watch?v=', 'embed/').split('&')[0])
    console.log("https://www.youtube.com/embed/hz9mbxUMg90")

    /*if(decodeURIComponent(url)==="https://qr.petco.com.mx/lps/t/7105/v/0000000133/u/https://www.youtube.com/embed/hz9mbxUMg90&t=18s"){
        return (
            <div className="video-responsive">
                <iframe 
                    width="340" 
                    height="250" 
                    src={decodeURIComponent(url).replace('watch?v=', 'embed/').split('$')[0]}
                    title="Video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>    
                </iframe>
            </div>
    
        );
    }*/

    return (
        <div className="video-responsive">
            <iframe 
                width="340" 
                height="250" 
                src={decodeURIComponent(url).replace('watch?v=', 'embed/').split('&')[0]}
                title="Video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>    
            </iframe>
        </div>

    );
}

EmbedVideo.propTypes = {
    url: PropTypes.string.isRequired
};

export default EmbedVideo;